import gql from 'graphql-tag'

export default {
    namespaced: true,

    state: {
        broadbandLabels: {
            accountFccLabelId: null,
            serviceClassCode: null,
            serviceDeviceCode: null,
            fccLabel: null,
        },
    },

    getters: {},
    mutations: {
        SET_BROADBANDLABEL({ broadbandLabels }, payload) {
            broadbandLabels = Object.assign(broadbandLabels, payload)
            delete broadbandLabels.__typename
        },
    },
    actions: {
        initialize({ dispatch }) {
            return Promise.all([
                dispatch('_getBROADBANDLABEL'),
            ])
                .then((all) => {
                    dispatch('moduleIsReady', { name: 'broadbandLabels', result: true }, { root: true })
                    return all
                })
                .catch((e) => {
                    dispatch(
                        'moduleIsReady',
                        { name: 'broadbandLabels', result: { error: e } },
                        { root: { error: e } }
                    )
                })
        },
        _getBROADBANDLABEL({ commit }) {
            return new Promise((resolve, reject) => {
                window.$apollo.addSmartQuery('getBroadbandLabel', {
                    query: gql`
                        query getBroadbandLabel  {
                            getBroadbandLabel {
                            accountFccLabelId
                            serviceClassCode
                            serviceDeviceCode
                            fccLabel{
                                fccLabelId
                                frn
                            }
                        }
                        }
                        `,
                    result: (r) => {
                        r.data?.getBroadbandLabel &&
                            resolve(commit('SET_BROADBANDLABEL', r.data.getBroadbandLabel))
                    },
                    error: (e) =>
                        reject(e)
                }
                )
            }).catch(e => {
                throw e?.message?.includes("server error") ? "broadbandLabel server error" : `broadbandLabel error: ${e}`
            });
        },
    },
}
