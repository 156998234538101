<template>
  <div>
    <AppPanel
      v-if="hasPromiseToPayScheduled && !isSummary"
      variant="alternate">
      <div
        class="flex flex-col items-start justify-between gap-4 lg:mt-4 lg:flex-row lg:items-center">
        <template v-if="amountDue > 0">
          <div>
            <div v-if="isOnAutopay">
              Next Autopay Date:
              {{ toFriendlyDate(autopayDraftDate, 'super-short-numeric-slash') }}
            </div>
            <div v-else-if="hadAutopay">
              Next Balance Due:
              {{
                autopayDraftDate
                  ? toFriendlyDate(autopayDraftDate, 'super-short-numeric-slash')
                  : ''
              }}
            </div>
            <div
              v-else-if="isPastDue"
              class="mb-2 flex flex-col items-start gap-2 text-navy-dark">
              <span class="bg-salmon px-8 py-1 text-lg font-bold">Past Due Balance</span>
              <span> Due on: {{ dueDate }} </span>
              <div class="text-5xl font-heavy text-salmon lg:text-6xl">${{ pastDueBalance }}</div>
              <div class="mt-2 flex items-center gap-2">
                <BzlIconButton
                  v-if="showDisclaimer"
                  name="info-circle-fill"
                  label="More Information"
                  @click="(e) => $emit('on-disclaimer-click', e)"></BzlIconButton>
                <span class="text-gray-dark"
                  >You’ve promised to pay your past due balance of ${{ promiseToPayAmount }}
                  by
                  {{ toFriendlyDate(promiseToPayDate, 'short-numeric-slash') }}
                </span>
              </div>
              <div class="flex flex-col items-start lg:flex-row lg:gap-4">
                <BzlButton
                  v-if="!isTodayOrBefore(promiseToPayDate)"
                  variant="text"
                  class="track leading-none underline"
                  size="large"
                  data-ga-category="Promise to Pay"
                  data-ga-action="trigger_confirm_modify_of_promise_to_pay"
                  data-ga-label="User clicked the submit to Modify promise to pay button"
                  @click="$emit('review-promise-to-pay')">
                  Review or Modify Payment
                  <BzlIcon
                    :custom-icon-name="icon.name"
                    :src="icon.src"></BzlIcon>
                </BzlButton>
                <BzlButton
                  variant="text"
                  class="leading-none underline"
                  size="large"
                  @click="billExplainer()">
                  Bill Explainer
                  <BzlIcon
                    :ustom-icon-name="icon.name"
                    :src="icon.src"></BzlIcon>
                </BzlButton>
              </div>
            </div>
            <div v-else>
              Next Balance Due:
              {{ dueDate ? toFriendlyDate(dueDate, 'super-short-numeric-slash') : '' }}
            </div>

            <span
              v-if="hasPendingPayment"
              class="flex items-center gap-2 text-sm text-gray-dark">
              <BzlIconButton
                v-if="showDisclaimer"
                name="info-circle-fill"
                label="More Information"></BzlIconButton>
              You have one or more pending payments totaling ${{ hasPendingPayment }}
            </span>
          </div>
        </template>
        <template v-else-if="amountDue < 0">
          <span class="text-5xl font-heavy text-salmon lg:text-6xl">{{ negativeValue }}</span>
        </template>
        <template v-else> No payment due at this time </template>

        <div
          v-if="isPromiseToPayEligible"
          class="flex w-full flex-row justify-center gap-4 lg:w-auto">
          <BzlButton
            :m-full="false"
            variant="primary"
            @click="$emit('pay-now'), trackEvent('trigger_make_a_payment_clicked')">
            Pay Now
          </BzlButton>
        </div>
        <div
          v-else
          class="flex flex-row justify-center">
          <BzlButton
            :m-full="false"
            @click="$emit('pay-now')">
            Make a Payment
          </BzlButton>
        </div>
      </div>
      <hr />
      <slot></slot>
    </AppPanel>

    <AppPanel
      v-else-if="isSummary && hasPromiseToPayScheduled"
      label="Billing"
      height="full"
      route-name="billing"
      @mousedown.native="billing()">
      <div class="mt-8">
        <div
          v-if="expiryNotice"
          class="mb-2 mt-2 flex w-full flex-row items-center justify-center gap-2 rounded bg-danger px-4 py-4 lg:px-8">
          <div class="text-left text-sm text-white">{{ expiryNotice }}</div>
        </div>
        <template v-if="amountDue > 0">
          <div>
            <div v-if="isOnAutopay">
              Due on:
              {{ toFriendlyDate(autopayDraftDate) }}
            </div>
            <div v-else-if="hadAutopay">Due on: {{ toFriendlyDate(autopayDraftDate) }}</div>
            <div
              v-else-if="isPastDue"
              class="flex flex-col items-start gap-2 text-navy-dark">
              <span class="bg-salmon px-8 py-1 text-lg font-bold">Past Due Balance</span>
              <span> Due on: {{ dueDate }} </span>
            </div>
            <div v-else>
              Due on: {{ toFriendlyDate(dueDate, 'localized-date-with-abbreviated-month') }}
            </div>
            <template v-if="amountDue > 0">
              <div v-if="isOnAutopay">
                Next Autopay Date:
                {{ toFriendlyDate(autopayDraftDate, 'super-short-numeric-slash') }}
              </div>
              <div v-else-if="hadAutopay">
                Next Balance Due:
                {{
                  autopayDraftDate
                    ? toFriendlyDate(autopayDraftDate, 'super-short-numeric-slash')
                    : ''
                }}
              </div>
              <div v-else>
                Next Balance Due:
                {{ dueDate ? toFriendlyDate(dueDate, 'super-short-numeric-slash') : '' }}
              </div>

              <span class="text-5xl font-heavy text-salmon lg:text-6xl">
                ${{ pastDueBalance }}
              </span>
            </template>
            <div v-else>No Additional payment due at this time</div>
          </div>
        </template>
        <template v-else-if="amountDue < 0">
          <span class="text-5xl font-heavy text-salmon lg:text-6xl"> {{ negativeValue }} </span>
        </template>
        <template v-else> No payment due at this time </template>
        <span
          v-if="hasPendingPayment"
          class="flex items-center gap-2 text-sm text-gray-dark">
          <BzlIconButton
            v-if="showDisclaimer"
            name="info-circle-fill"
            label="More Information"></BzlIconButton>
          You have one or more pending payments totaling ${{ hasPendingPayment }}
        </span>
      </div>

      <BzlButton
        variant="text"
        size="large"
        class="underline"
        @click="billExplainer()">
        Bill Explainer
        <BzlIcon
          :custom-icon-name="icon.name"
          :src="icon.src"></BzlIcon>
      </BzlButton>

      <div
        v-if="isPromiseToPayEligible"
        class="flex w-full w-full flex-col justify-start gap-4 lg:flex-row">
        <BzlButton
          variant="primary"
          :m-full="false"
          @click="$emit('pay-now'), trackEvent('trigger_make_a_payment_clicked')">
          Pay Now
        </BzlButton>
      </div>
      <div v-else>
        <BzlButton
          :m-full="false"
          @click="$emit('pay-now')">
          Make a Payment
        </BzlButton>
      </div>
      <slot></slot>
    </AppPanel>

    <AppPanel
      v-else-if="isSummary"
      label="Billing"
      height="full"
      route-name="billing"
      @mousedown.native="billing()">
      <div class="mt-8">
        <div
          v-if="expiryNotice"
          class="mb-2 flex w-full flex-row items-center justify-center gap-2 rounded bg-danger px-4 py-4 lg:px-8">
          <div class="text-left text-sm text-white">{{ expiryNotice }}</div>
        </div>
        <template v-if="amountDue > 0">
          <div>
            <div v-if="isOnAutopay">
              Due on:
              {{ toFriendlyDate(autopayDraftDate) }}
            </div>
            <div v-else-if="hadAutopay">Due on: {{ toFriendlyDate(autopayDraftDate) }}</div>
            <div
              v-else-if="isPastDue"
              class="mb-2 flex flex-col items-start gap-2 text-navy-dark">
              <span class="bg-salmon px-8 py-1 text-lg font-bold">Past Due Balance</span>
              <span> Due on: {{ dueDate }} </span>
            </div>
            <div v-else>Due on: {{ toFriendlyDate(dueDate) }}</div>
            <span class="text-5xl font-heavy text-salmon lg:text-6xl"
              >${{ formattedAmountDue }}</span
            >
          </div>
        </template>
        <template v-else-if="amountDue < 0">
          <div>No payment due at this time</div>
          <span class="text-5xl font-heavy text-salmon lg:text-6xl">{{ negativeValue }}</span>
        </template>
        <template v-else> No payment due at this time </template>
      </div>

      <span
        v-if="hasPendingPayment"
        class="flex items-center gap-2 text-sm text-gray-dark">
        <BzlIconButton
          v-if="showDisclaimer"
          name="info-circle-fill"
          label="More Information"></BzlIconButton>
        You have one or more pending payments totaling ${{ hasPendingPayment }}
      </span>

      <BzlButton
        variant="text"
        size="large"
        class="underline"
        @click="billExplainer()">
        Bill Explainer
        <BzlIcon
          :custom-icon-name="icon.name"
          :src="icon.src"></BzlIcon>
      </BzlButton>

      <div
        v-if="isPromiseToPayEligible"
        class="flex w-full flex-col justify-start gap-4 lg:flex-row">
        <BzlButton
          variant="primary"
          :m-full="false"
          @click="$emit('pay-now'), trackEvent('trigger_make_a_payment_clicked')">
          Pay Now
        </BzlButton>
        <BzlButton
          variant="primary"
          :m-full="false"
          outline
          @click="promiseTopay(), trackEvent('trigger_promise_to_pay_clicked')">
          Schedule Promise To Pay
        </BzlButton>
      </div>
      <div v-else>
        <BzlButton @click="$emit('pay-now')"> Make a Payment </BzlButton>
      </div>

      <slot></slot>
    </AppPanel>

    <AppPanel
      v-else
      variant="alternate">
      <div
        v-if="expiryNotice"
        class="flex w-full flex-row items-center justify-center gap-2 rounded bg-danger px-4 py-4 lg:px-12">
        <div class="text-left text-sm text-white">{{ expiryNotice }}</div>
      </div>

      <div
        class="flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-center lg:py-5">
        <template v-if="amountDue > 0">
          <div class="flex w-full flex-col gap-2">
            <div v-if="isOnAutopay">
              <div class="text-lg font-bold">Next Payment:</div>
              {{ toFriendlyDate(autopayDraftDate) }}
            </div>
            <div v-else-if="hadAutopay">Due on: {{ toFriendlyDate(autopayDraftDate) }}</div>
            <div
              v-else-if="isPastDue"
              class="flex flex-col items-start gap-2 text-navy-dark">
              <span class="bg-salmon px-8 py-1 text-lg font-bold">Past Due Balance</span>
              <span> Due on: {{ dueDate }} </span>
            </div>
            <div v-else>
              Due on: {{ toFriendlyDate(dueDate, 'localized-date-with-abbreviated-month') }}
            </div>
            <div class="text-5xl font-heavy text-navy-dark lg:text-6xl">
              ${{ formattedAmountDue }}
            </div>
            <span
              v-if="hasPendingPayment"
              class="flex items-center gap-2 text-sm text-gray-dark">
              <BzlIconButton
                v-if="showDisclaimer"
                name="info-circle-fill"
                label="More Information"></BzlIconButton>
              You have one or more pending payments totaling ${{ hasPendingPayment }}
            </span>
            <div
              v-if="isPromiseToPayEligible"
              class="flex flex-col items-start gap-4 lg:flex-row lg:items-end">
              <BzlButton
                variant="text"
                class="leading-none underline"
                size="large"
                href="#StatementHistory">
                Statements
                <BzlIcon
                  :custom-icon-name="icon.name"
                  :src="icon.src"></BzlIcon>
              </BzlButton>
              <BzlButton
                variant="text"
                class="leading-none underline"
                size="large"
                @click="billExplainer()">
                Bill Explainer
                <BzlIcon
                  :custom-icon-name="icon.name"
                  :src="icon.src"></BzlIcon>
              </BzlButton>
            </div>
            <div
              v-else
              class="flex gap-4">
              <BzlButton
                variant="text"
                class="leading-none underline"
                size="large"
                @click="billExplainer()">
                Bill Explainer
                <BzlIcon
                  :custom-icon-name="icon.name"
                  :src="icon.src"></BzlIcon>
              </BzlButton>
            </div>
          </div>
        </template>
        <template v-else-if="amountDue < 0">
          <div>
            <div>No payment due at this time</div>
            <span class="text-5xl font-heavy text-salmon lg:text-6xl">
              {{ negativeValue }}
            </span>
            <span
              v-if="hasPendingPayment"
              class="flex items-center gap-2 text-sm text-gray-dark">
              <BzlIconButton
                v-if="showDisclaimer"
                name="info-circle-fill"
                label="More Information"></BzlIconButton>
              You have one or more pending payments totaling ${{ hasPendingPayment }}
            </span>
          </div>
        </template>
        <template v-else> No payment due at this time </template>

        <div
          v-if="isPromiseToPayEligible"
          class="flex w-full flex-col justify-end gap-4 lg:flex-row">
          <BzlButton
            variant="primary"
            @click="$emit('pay-now'), trackEvent('trigger_make_a_payment_clicked')">
            Pay Now
          </BzlButton>
          <BzlButton
            variant="primary"
            outline
            @click="$emit('promise-to-pay')">
            Schedule Promise To Pay
          </BzlButton>
        </div>
        <div
          v-else
          class="flex flex-row justify-center">
          <BzlButton @click="$emit('pay-now')"> Make a Payment </BzlButton>
        </div>
      </div>

      <hr />
      <slot></slot>
    </AppPanel>
    <slot name="modals"></slot>
  </div>
</template>

<script>
  import chevronArrow from './chevron-arrow.svg'

  export default {
    name: 'MakeAPayment',
    props: {
      variant: {
        type: String,
        default: 'full',
        validator: (val) => ['full', 'compact'].includes(val),
      },
      amountDue: {
        type: Number,
        default: 0,
      },
      isPastDue: {
        type: Boolean,
        default: false,
      },
      dueDate: {
        type: String, // "YYYY-MM-DD"
        default: null,
      },
      isPromiseToPayEligible: {
        type: Boolean,
        default: false,
      },
      hasPromiseToPayScheduled: {
        type: Boolean,
        default: false,
      },
      promiseToPayDate: {
        type: String, // "YYYY-MM-DD"
        default: '',
      },
      promiseToPayAmount: {
        type: Number,
        default: 0,
      },
      isOnAutopay: {
        type: Boolean,
        default: false,
      },
      hadAutopay: {
        type: Boolean,
        default: false,
      },
      autopayDraftDate: {
        type: String, // "YYYY-MM-DD"
        default: null,
      },
      expiryNotice: {
        type: [String, null],
        default: null,
      },
      isSummary: {
        type: Boolean,
        default: false,
        required: false,
      },
      available: {
        type: Boolean,
        default: false,
      },
      paymentMethod: {
        type: [Object, null],
        default: null,
      },
      showDisclaimer: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasPendingPayment: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      icon: {
        name: 'Chevron Arrow',
        src: chevronArrow,
      },
    }),
    computed: {
      formattedAmountDue() {
        return this.amountDue.toFixed(2)
      },
      pastDueBalance() {
        return (this.amountDue - this.promiseToPayAmount).toFixed(2)
      },
      negativeValue() {
        const money = this.amountDue
        const format = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(money) // '$100.00'
        return format
      },
    },
    methods: {
      billing() {
        this.trackEvent('trigger_billing_clicked')
      },
      billExplainer() {
        this.trackEvent('trigger_bill_explainer_clicked')
        this.$emit('view-bill-explainer')
      },
      promiseTopay() {
        this.trackEvent('B_SCHEDULED_PAYMENTS')
        this.$emit('promise-to-pay')
      },
    },
  }
</script>

<style scoped>
  .BzlButton[variant='text']::part(base) {
    @apply leading-none;
  }

  .BzlIconButton::part(base) {
    @apply text-blue hover:text-blue-light;
  }
</style>
