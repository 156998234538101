<template>
  <div>
    <BzlAlert
      :open="open"
      :closable="true"
      variant="banner"
      @sl-hide="close()">
      <div class="mt-8 flex flex-col gap-4 lg:mt-0 lg:flex-row lg:gap-8">
        <div class="grid grow grid-cols-12 items-start gap-x-6 gap-y-4">
          <div class="col-span-3 md:col-span-2">
            <img
              :src="img"
              class="w-full"
              alt="My Breezeline App Icon" />
          </div>
          <div class="col-span-9 md:col-span-10 md:items-start">
            <div class="mb-2 text-xl font-bold leading-tight lg:text-3xl">
              It's All In Your Hands
            </div>
            <div class="text-lg">
              Access your Breezeline account from practically anywhere with the new My Breezeline
              Mobile App.
            </div>
          </div>
          <div class="col-span-full lg:col-start-3 lg:col-end-13">
            <div class="flex items-center">
              <a
                class="lg:hidden"
                href="https://apps.apple.com/us/app/my-breezeline/id1568553022"
                target="_blank"
                @click="trackEvent('AD_MY_BREEZELINE')">
                <img
                  src="./app-store-logo.svg"
                  alt="Download app on the Apple App Store"
                  class="w-28 lg:w-32" />
              </a>
              <a
                class="ml-4 lg:hidden"
                href="https://play.google.com/store/apps/details?id=com.breezeline&hl=en_US&gl=US"
                target="_blank"
                @click="trackEvent('AD_MY_BREEZELINE')">
                <img
                  src="./google-play-logo.svg"
                  alt="Download app on Google Play"
                  class="w-28 lg:w-32" />
              </a>
            </div>
            <BzlButton
              variant="default"
              class="hidden w-full lg:block lg:w-auto"
              size="large"
              @click="modals.qrCode = true">
              Show QR Code
            </BzlButton>
          </div>
        </div>
        <div class="shrink-0 basis-1/3">
          <ul class="xl:text-md flex flex-col gap-2 space-y-2 text-base">
            <li class="flex items-start gap-2">
              <BzlIcon
                :custom-icon-name="icon.name"
                :src="icon.src"
                class="mt-0.5 shrink-0"></BzlIcon>
              <span class="leading-tight">View your account</span>
            </li>
            <li class="flex items-start gap-2">
              <BzlIcon
                :custom-icon-name="icon.name"
                :src="icon.src"
                class="mt-0.5 shrink-0"></BzlIcon>
              <span class="leading-tight">Make a payment</span>
            </li>
            <li class="flex items-start gap-2">
              <BzlIcon
                :custom-icon-name="icon.name"
                :src="icon.src"
                class="mt-0.5 shrink-0"></BzlIcon>
              <span class="leading-tight">Change payment methods</span>
            </li>
            <li class="flex items-start gap-2">
              <BzlIcon
                :custom-icon-name="icon.name"
                :src="icon.src"
                class="mt-0.5 shrink-0"></BzlIcon>
              <span class="leading-tight">Enroll in AutoPay+EcoSave</span>
            </li>
            <li class="flex items-start gap-2">
              <BzlIcon
                :custom-icon-name="icon.name"
                :src="icon.src"
                class="mt-0.5 shrink-0"></BzlIcon>
              <span class="leading-tight">Troubleshoot WiFi &amp; modem reboot</span>
            </li>
          </ul>
        </div>
      </div>
    </BzlAlert>

    <BzlModal
      :open="modals.qrCode"
      label="Scan QR Code"
      @sl-after-hide="modals.qrCode = false">
      <div class="flex flex-col gap-4">
        <div>
          Scan the QR code below using your iOS or Android phone's camera to install the new app!
        </div>
        <div class="flex flex-row flex-wrap justify-around text-center text-black">
          <a :href="mobileStoreUrl">
            <img
              :src="qrCode"
              alt="QR code"
              class="h-36 w-36" />
          </a>
        </div>
        <hr class="border-gray" />
        <div class="flex w-full flex-row justify-center justify-around">
          <BzlButton @click="modals.qrCode = false">Close</BzlButton>
        </div>
      </div>
    </BzlModal>
  </div>
</template>

<script>
  import MyBzlAppIcon from './my-breezeline-app-icon.png'
  import MyAccountQr from './qr.png'
  import CheckmarkIcon from './checkmark-icon.svg'

  export default {
    name: 'AppPromoBanner',
    props: {
      open: {
        default: true,
        type: Boolean,
        required: false,
      },
    },
    data: () => ({
      img: MyBzlAppIcon,
      modals: {
        qrCode: false,
      },
      qrCode: MyAccountQr || [],
      icon: {
        name: 'Checkmark Icon',
        src: CheckmarkIcon,
      },
    }),
    computed: {
      mobileStoreUrl() {
        const endpoint = import.meta.env.VITE_API_URL
          ? import.meta.env.VITE_API_URL
          : 'https://api.breezeline.com'

        return `${endpoint}/api/redirect/my-breezeline`
      },
    },
    methods: {
      close() {
        this.$emit('close-mobile-app-banner')
      },
    },
  }
</script>

<style></style>
